import React from 'react'
import './bylaw.css'
import ReactMarkdown from 'react-markdown'
import MDEditor from '@uiw/react-md-editor';

const Allbylaw = ({
  Title,
  Paragraph,
  bylaw_font_color,
  bylaw_font_family,
  bylaw_font_size,
  bylaw_font_weight,
}) => {
  return (
    <div>
      <p className="title-bylaw pading undline">{Title}</p>
      <div className="para-left pading half-size about-content">
        <div>
        <MDEditor.Markdown 
        
        style={{
          fontFamily: bylaw_font_family,
          color: bylaw_font_color,
          fontSize: bylaw_font_size,
          fontWeight: bylaw_font_weight,
        }}

        source={Paragraph}/>
          {/* <ReactMarkdown
            style={{
              fontFamily: bylaw_font_family,
              color: bylaw_font_color,
              fontSize: bylaw_font_size,
              fontWeight: bylaw_font_weight,
              fontFamily: "cursive",
            }}
            source={Paragraph}
          /> */}
        </div>
      </div>
    </div>
  )
}

export default Allbylaw