import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Allbylaw from '../components/Allbylaw'
import '../components/about.css'

const bylaw = ({
  data: {
    allStrapiBylaws: { nodes: bylawlist },
  },
  location,
}) => {
  return (
    <Layout location={location} crumbLabel="Bylaws">
      <div className="container">
        <div className="box">
          <div className="single-post" style={{backgroundColor:  "rgb(249, 249, 255)"}} >
          <h2
              class="membershipTitle"
              style={{
                fontFamily: "arial",
                fontSize: "28px",
                fontWeight: "600",
                textAlign: "center",
                color: "black",
                paddingTop:"23px",
                
              }}
            >
              Bylaws
            </h2>
            {' '}
            {bylawlist.map((bylawlists, index) => {
              return <Allbylaw key={index} {...bylawlists} />
            })}{' '}
          </div>{' '}
        </div>{' '}
      </div>{' '}
    </Layout>
  )
}
export const query = graphql`
  {
    allStrapiBylaws(sort: {fields: order, order: ASC}) {
      nodes {
        bylaw_font_color {
          color
        }
        bylaw_font_family {
          family
        }
        bylaw_font_size {
          size
        }
        bylaw_font_weight {
          weight
        }
        slug
        order
        Paragraph
        Title
        id
      }
    }
  }
`
export default bylaw